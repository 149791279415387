<template>
  <Panel
    :close="true"
    @close-panel="close()"
    :nomax="true"
    :tabsSpace="tabsSpace"
    :print="true"
  >
    <div class="panel__content">
      <!-- PRINT -->
      <div class="print__header" :class="{ 'no-print': !printExamination }">
        <header>
          <div class="logo">
            <img src="../../assets/img/logo-sm.svg" alt="3V-Rentgen" />
            <img src="../../assets/img/logo-text.svg" alt="3V-Rentgen" />
          </div>
          <div class="title">
            <h1>
              <strong>НАПРАВЛЕНИЕ</strong>
            </h1>
            <h1>ЗА РЕНТГЕНОГРАФИЯ</h1>
            <div class="placeholder">
              <p>{{ examination.createdAt | formatDate }}</p>
              <span>Дата на заявлението</span>
            </div>
          </div>
        </header>
        <div class="row">
          <div class="placeholder">
            <p>{{ examination.createdBy ? examination.createdBy.name : "" }}</p>
            <span>Лекар</span>
          </div>
          <div class="placeholder">
            <p>
              {{ examination.createdBy ? examination.createdBy.location : "" }}
            </p>
            <span>Адрес на практика (клиника)</span>
          </div>
          <div class="placeholder">
            <p>
              {{ examination.createdBy ? examination.createdBy.phone : "" }}
            </p>
            <span>Телефон</span>
          </div>
        </div>
        <div class="row">
          <div class="placeholder">
            <p>{{ examination.user ? examination.user.name : "" }}</p>
            <span>Пациент</span>
          </div>
          <div class="placeholder">
            <p>{{ examination.user ? examination.user.egn : "" }}</p>
            <span>ЕГН/Рождена дата/възраст (по избор)</span>
          </div>
        </div>
      </div>
      <!-- NO PRINT -->
      <div class="header">
        <div class="header__left">
          <div class="icon">g</div>
          <div v-if="id">
            <p class="strong">Преглед на направление за рентгенография</p>
            <p>{{ examination.createdAt | formatDateFull }}</p>
          </div>
          <div v-else>
            <p class="strong">Ново онлайн направление за рентгенография</p>
            <p>Направлението все още не е запазено</p>
          </div>
        </div>
        <div class="buttons">
          <Button
            class="red delete button--mobile-circle"
            @click="cancel()"
            v-if="id && !photos.length && !examination.canceled"
          >
            <span class="icon">L</span>
          </Button>
          <Button
            class="dark desktop"
            @click="print(false)"
            v-if="
              currentUser.role === 'admin' || currentUser.role === 'employee'
            "
            title="Отворете и разпечатайте картончето с данните на пациента"
          >
            <span class="icon">i</span>
            <span>КАРТОНЧЕ</span>
          </Button>
          <Button
            class="dark desktop"
            @click="print(true)"
            v-if="!photosMode"
            title="Разпечатайте направлението"
          >
            <span class="icon">i</span>
            <span>ПРИНТИРАНЕ</span>
          </Button>
          <Button
            class="green button--mobile-circle"
            @click="photosMode = false"
            v-if="id && photosMode"
          >
            <span class="icon">H</span>
            <span>ОТВОРИ НАПРАВЛЕНИЕ</span>
          </Button>
          <template v-else-if="id">
            <Button
              class="green button--mobile-circle"
              @click="isPhotoUploadOpen = true"
              v-if="
                currentUser.role === 'admin' || currentUser.role === 'employee'
              "
            >
              <span class="icon">W</span>
              <span>КАЧИ СНИМКИ</span>
            </Button>
            <Button
              class="green button--mobile-circle"
              @click="photosMode = true"
              v-else
            >
              <span class="icon">b</span>
              <span>ОТВОРИ СНИМКИ</span>
            </Button>
          </template>
          <!-- v-if="!id || (status !== 'finished' && currentUser.role === 'dentist')" -->
          <Button
            class="dark button--mobile-circle"
            @click="save()"
            v-if="!photosMode && currentUser.role !== 'pacient'"
            :title="id ? 'Запишете промените' : ''"
          >
            <template v-if="!id">
              <span class="icon">j</span>
              <span>ЗАПИШИ</span>
            </template>
            <span class="icon edit" v-else>j</span>
          </Button>
        </div>
      </div>
      <Photos
        :photos="photos"
        :hide="resizing"
        v-if="photosMode"
        @update-photos="getPhotos"
        @edit-photo="editPhoto"
      />
      <div
        class="examination"
        :class="{ 'no-print': !printExamination }"
        v-else
      >
        <div class="row">
          <div class="box box-1">
            <div class="box__header">
              <h3>РЕНТГЕНОГРАФИЯ НА ЗЪБ/И</h3>
            </div>
            <div class="box__content">
              <div class="checkboxes">
                <p>По Dieck</p>
                <Checkbox
                  v-model="examination.roentgenography.dikDigital"
                  label="Дигитална"
                />
                <Checkbox
                  v-model="examination.roentgenography.dikFilm"
                  label="На филм"
                />
              </div>
              <div class="checkboxes">
                <p>По паралелна техника</p>
                <Checkbox
                  v-model="examination.roentgenography.parallelDigital"
                  label="Дигитална"
                />
                <Checkbox
                  v-model="examination.roentgenography.parallelFilm"
                  label="На филм"
                />
              </div>
              <Teeth
                v-model="examination.roentgenography.teeth"
                @activated="
                  !(
                    examination.roentgenography.dikDigital ||
                    examination.roentgenography.dikFilm ||
                    examination.roentgenography.parallelDigital ||
                    examination.roentgenography.parallelFilm
                  )
                    ? (examination.roentgenography.dikDigital = true)
                    : null
                "
                @deactivated="
                  (examination.roentgenography.dikDigital = false),
                    (examination.roentgenography.dikFilm = false),
                    (examination.roentgenography.parallelDigital = false),
                    (examination.roentgenography.parallelFilm = false)
                "
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="box">
            <div class="box__header">
              <h3>RO GR ЗА КАРИЕС ДИАГНОСТИКА /BITE-WING/</h3>
            </div>
            <div class="box__content">
              <div class="checkboxes">
                <Checkbox
                  v-model="examination.rogr.digital"
                  label="Дигитална"
                  @activated="
                    !(examination.rogr.left || examination.rogr.right)
                      ? (examination.rogr.left = true)
                      : null
                  "
                  @deactivated="
                    (examination.rogr.left = false),
                      (examination.rogr.right = false)
                  "
                >
                  <div class="big-checkboxes-row">
                    <BigCheckbox
                      v-model="examination.rogr.left"
                      label="L"
                      @activated="examination.rogr.digital = true"
                      @deactivated="
                        examination.rogr.right
                          ? null
                          : (examination.rogr.digital = false)
                      "
                    />
                    <BigCheckbox
                      v-model="examination.rogr.right"
                      label="R"
                      @activated="examination.rogr.digital = true"
                      @deactivated="
                        examination.rogr.left
                          ? null
                          : (examination.rogr.digital = false)
                      "
                    />
                  </div>
                </Checkbox>
                <Checkbox v-model="examination.rogr.film" label="На филм" />
              </div>
            </div>
          </div>
          <div class="box">
            <div class="box__header">
              <h3>ПАРАДОНТАЛЕН СТАТУС</h3>
            </div>
            <div class="box__content">
              <div class="checkboxes">
                <Checkbox
                  v-model="examination.paradontalStatus.paradontalStatus"
                  label="Парадонтален статус"
                />
              </div>
            </div>
          </div>
          <div class="box">
            <div class="box__header">
              <h3>ОКЛУЗАЛНИ</h3>
            </div>
            <div class="box__content">
              <div class="checkboxes">
                <Checkbox
                  v-model="examination.occlusal.belot"
                  label="Коса по Belot (Горна челюст)"
                />
                <Checkbox
                  v-model="examination.occlusal.simpson"
                  label="Аксиална по Simpson (Долна челюст)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="box">
            <div class="box__header">
              <h3>ДИГИТАЛНА ОРТОПАНТОМОГРАФИЯ</h3>
            </div>
            <div class="box__content box__content--start">
              <div class="checkboxes">
                <Checkbox
                  v-model="examination.orthopantomography.default"
                  label="Дигитална ортопантомография"
                />
                <Checkbox
                  v-model="examination.orthopantomography.tms"
                  label="Дигитална ортопантомография на ТМС"
                />
                <Checkbox
                  v-model="examination.orthopantomography.sector"
                  label="Секторна"
                  @activated="
                    !(
                      examination.orthopantomography.first ||
                      examination.orthopantomography.second ||
                      examination.orthopantomography.third
                    )
                      ? (examination.orthopantomography.first = true)
                      : null
                  "
                  @deactivated="
                    (examination.orthopantomography.first = false),
                      (examination.orthopantomography.second = false),
                      (examination.orthopantomography.third = false)
                  "
                >
                  <table class="big-checkboxes-three">
                    <tr>
                      <td>
                        <BigCheckbox
                          v-model="examination.orthopantomography.first"
                          label="I"
                          @activated="
                            examination.orthopantomography.sector = true
                          "
                          @deactivated="
                            examination.orthopantomography.second ||
                            examination.orthopantomography.third
                              ? null
                              : (examination.orthopantomography.sector = false)
                          "
                        />
                      </td>
                      <td>
                        <BigCheckbox
                          v-model="examination.orthopantomography.second"
                          label="II"
                          @activated="
                            examination.orthopantomography.sector = true
                          "
                          @deactivated="
                            examination.orthopantomography.first ||
                            examination.orthopantomography.third
                              ? null
                              : (examination.orthopantomography.sector = false)
                          "
                        />
                      </td>
                      <td>
                        <BigCheckbox
                          v-model="examination.orthopantomography.third"
                          label="III"
                          @activated="
                            examination.orthopantomography.sector = true
                          "
                          @deactivated="
                            examination.orthopantomography.first ||
                            examination.orthopantomography.second
                              ? null
                              : (examination.orthopantomography.sector = false)
                          "
                        />
                      </td>
                    </tr>
                  </table>
                </Checkbox>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="box__header">
              <h3>РЕНТГЕНОГРАФИИ НА ЧЕРЕП</h3>
            </div>
            <div class="box__content">
              <div class="checkboxes">
                <Checkbox
                  v-model="examination.skull.profileTeleradiography"
                  label="Профилна телерентгенография"
                />
                <Checkbox
                  v-model="examination.skull.facialTeleradiography"
                  label="Лицева телерентгенография"
                />
                <Checkbox
                  v-model="examination.skull.paranasalCavities"
                  label="Околоносни кухини - дигитална"
                />
                <Checkbox
                  v-model="examination.skull.hirz"
                  label="Рентгенография по Хирц"
                />
                <Checkbox
                  v-model="examination.skull.skull"
                  label="Рентгенография на череп (анфас / профил)"
                  @activated="
                    !(examination.skull.facial || examination.skull.profile)
                      ? (examination.skull.facial = true)
                      : null
                  "
                  @deactivated="
                    (examination.skull.facial = false),
                      (examination.skull.profile = false)
                  "
                >
                  <div class="big-checkboxes-row">
                    <BigCheckbox
                      v-model="examination.skull.facial"
                      icon="6"
                      @activated="examination.skull.skull = true"
                      @deactivated="
                        examination.skull.profile
                          ? null
                          : (examination.skull.skull = false)
                      "
                    />
                    <BigCheckbox
                      v-model="examination.skull.profile"
                      icon="7"
                      @activated="examination.skull.skull = true"
                      @deactivated="
                        examination.skull.facial
                          ? null
                          : (examination.skull.skull = false)
                      "
                    />
                  </div>
                </Checkbox>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="box__header">
              <h3>ДРУГИ ЦЕНТРАЖИ</h3>
            </div>
            <div class="box__content box__content--start">
              <div class="checkboxes">
                <Checkbox
                  v-model="examination.others.cervicalVertebrae"
                  label="На шийни прешлени - дигитална"
                />
                <Checkbox
                  v-model="examination.others.wrist"
                  label="На китка - дигитална"
                />
                <Checkbox
                  v-model="examination.others.nasalBones"
                  label="Носни кости"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="box-wrap">
          <div class="row">
            <div class="box box-4">
              <div class="box__header">
                <h3>КОМПЮТЪРНА ТОМОГРАФИЯ (СВСТ)</h3>
              </div>
              <div class="box__content">
                <div class="checkboxes">
                  <Checkbox
                    v-model="examination.svst.fourByFour"
                    label="Малък обем (4х4) в участъка"
                  />
                  <Teeth
                    v-model="examination.svst.teeth"
                    @activated="examination.svst.fourByFour = true"
                    @deactivated="examination.svst.fourByFour = false"
                  />
                  <div class="halfs">
                    <div class="half half--padding-right">
                      <Checkbox
                        v-model="examination.svst.halfBoth"
                        label="Горна и долна челюстни половини"
                        @activated="
                          !(examination.svst.left || examination.svst.right)
                            ? (examination.svst.left = true)
                            : null
                        "
                        @deactivated="
                          (examination.svst.left = false),
                            (examination.svst.right = false)
                        "
                      >
                        <div class="big-checkboxes-row">
                          <BigCheckbox
                            v-model="examination.svst.left"
                            label="L"
                            @activated="examination.svst.halfBoth = true"
                            @deactivated="
                              examination.svst.right
                                ? null
                                : (examination.svst.halfBoth = false)
                            "
                          />
                          <BigCheckbox
                            v-model="examination.svst.right"
                            label="R"
                            @activated="examination.svst.halfBoth = true"
                            @deactivated="
                              examination.svst.left
                                ? null
                                : (examination.svst.halfBoth = false)
                            "
                          />
                        </div>
                      </Checkbox>
                      <Checkbox
                        v-model="examination.svst.fullUpper"
                        label="Цяла горна челюст"
                      />
                      <Checkbox
                        v-model="examination.svst.fullLower"
                        label="Цяла долна челюст"
                      />
                    </div>
                    <div class="half half--with-border">
                      <Checkbox
                        v-model="examination.svst.quadrant"
                        label="Квадрант"
                        @activated="
                          !(
                            examination.svst.quadrantTwo ||
                            examination.svst.quadrantTwo ||
                            examination.svst.quadrantThree ||
                            examination.svst.quadrantFour
                          )
                            ? (examination.svst.quadrantOne = true)
                            : null
                        "
                        @deactivated="
                          (examination.svst.quadrantOne = false),
                            (examination.svst.quadrantTwo = false),
                            (examination.svst.quadrantThree = false),
                            (examination.svst.quadrantFour = false)
                        "
                      >
                        <table class="big-checkboxes-quadrant">
                          <tr>
                            <td>
                              <BigCheckbox
                                v-model="examination.svst.quadrantOne"
                                label="I"
                                @activated="examination.svst.quadrant = true"
                                @deactivated="
                                  examination.svst.quadrantTwo ||
                                  examination.svst.quadrantThree ||
                                  examination.svst.quadrantFour
                                    ? null
                                    : (examination.svst.quadrant = false)
                                "
                              />
                            </td>
                            <td>
                              <BigCheckbox
                                v-model="examination.svst.quadrantTwo"
                                label="II"
                                @activated="examination.svst.quadrant = true"
                                @deactivated="
                                  examination.svst.quadrantOne ||
                                  examination.svst.quadrantThree ||
                                  examination.svst.quadrantFour
                                    ? null
                                    : (examination.svst.quadrant = false)
                                "
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <BigCheckbox
                                v-model="examination.svst.quadrantFour"
                                label="IV"
                                @activated="examination.svst.quadrant = true"
                                @deactivated="
                                  examination.svst.quadrantOne ||
                                  examination.svst.quadrantTwo ||
                                  examination.svst.quadrantThree
                                    ? null
                                    : (examination.svst.quadrant = false)
                                "
                              />
                            </td>
                            <td>
                              <BigCheckbox
                                v-model="examination.svst.quadrantThree"
                                label="III"
                                @activated="examination.svst.quadrant = true"
                                @deactivated="
                                  examination.svst.quadrantOne ||
                                  examination.svst.quadrantTwo ||
                                  examination.svst.quadrantFour
                                    ? null
                                    : (examination.svst.quadrant = false)
                                "
                              />
                            </td>
                          </tr>
                        </table>
                      </Checkbox>
                      <Checkbox
                        v-model="examination.svst.fullBoth"
                        label="Двете челюсти"
                      />
                    </div>
                  </div>
                </div>
                <div class="checkboxes">
                  <div class="rotated">
                    <p>Цел на изследването:</p>
                  </div>
                  <Checkbox
                    v-model="examination.svst.implantTreatment"
                    label="Имплантологично лечение"
                  />
                  <Checkbox
                    v-model="examination.svst.surgicalTreatment"
                    label="Хирургично лечение"
                  />
                  <Checkbox
                    v-model="examination.svst.sinusLifting"
                    label="Повдигане на синус (Sinus Lifting)"
                  />
                  <Checkbox
                    v-model="examination.svst.blockGraft"
                    label="Блок присадка (Block graft)"
                  />
                  <Checkbox
                    v-model="examination.svst.periodontalTreatment"
                    label="Парадонтално лечение"
                  />
                  <Checkbox
                    v-model="examination.svst.orthodonticTreatment"
                    label="Ортодонтско лечение"
                  />
                  <Checkbox
                    v-model="examination.svst.imaging"
                    label="Образна диагностига"
                  />
                  <Checkbox
                    v-model="examination.svst.endodonticTreatment"
                    label="Ендодонтско лечение"
                  />
                  <div class="input-radio">
                    <input
                      type="radio"
                      :id="`withReading-${uid}`"
                      :name="`reading-${uid}`"
                      :value="true"
                      v-model="examination.svst.reading"
                    />
                    <label :for="`withReading-${uid}`">С разчитане</label>
                    <input
                      type="radio"
                      :id="`withoutReading-${uid}`"
                      :name="`reading-${uid}`"
                      :value="false"
                      v-model="examination.svst.reading"
                    />
                    <label :for="`withoutReading-${uid}`">Без разчитане</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition name="fade">
        <PhotoUpload
          v-if="isPhotoUploadOpen"
          @close="closePhotoUpload"
          :id="selectedPhoto"
          :examination="id"
        />
      </transition>
      <PrintCard :u="user" v-show="!printExamination" />
    </div>
  </Panel>
</template>

<script>
import { mapGetters } from "vuex";
import Panel from "@/components/Panel.vue";
import Button from "@/components/Button.vue";
import Checkbox from "@/components/Checkbox.vue";
import BigCheckbox from "@/components/BigCheckbox.vue";
import Teeth from "@/components/Teeth.vue";
import PhotoUpload from "@/views/app/PhotoUpload.vue";
import Photos from "./components/Photos";
import PrintCard from "@/views/app/PrintCard";
export default {
  components: {
    Panel,
    Button,
    Teeth,
    Checkbox,
    BigCheckbox,
    PhotoUpload,
    Photos,
    PrintCard,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  props: ["id", "uid", "tabsSpace", "printIt"],
  data() {
    return {
      isPhotoUploadOpen: false,
      selectedPhoto: null,
      printExamination: true,
      photosMode: false,
      status: null,
      user: {},
      photos: [],
      timeout: null,
      resizing: false,
      examination: {
        user: this.uid,
        name: "",
        roentgenography: {
          dikDigital: false,
          dikFilm: false,
          parallelDigital: false,
          parallelFilm: false,
          teeth: [],
        },
        rogr: {
          digital: false,
          film: false,
          left: false,
          right: false,
        },
        paradontalStatus: {
          paradontalStatus: false,
        },
        occlusal: {
          belot: false,
          simpson: false,
        },
        orthopantomography: {
          default: false,
          tms: false,
          sector: false,
          first: false,
          second: false,
          third: false,
        },
        skull: {
          profileTeleradiography: false,
          facialTeleradiography: false,
          paranasalCavities: false,
          hirz: false,
          skull: false,
          facial: false,
          profile: false,
        },
        others: {
          cervicalVertebrae: false,
          wrist: false,
          nasalBones: false,
        },
        svst: {
          fourByFour: false,
          teeth: [],
          halfBoth: false,
          left: false,
          right: false,
          fullUpper: false,
          fullLower: false,
          quadrant: false,
          quadrantOne: false,
          quadrantTwo: false,
          quadrantThree: false,
          quadrantFour: false,
          fullBoth: false,
          implantTreatment: false,
          surgicalTreatment: false,
          sinusLifting: false,
          blockGraft: false,
          periodontalTreatment: false,
          orthodonticTreatment: false,
          imaging: false,
          endodonticTreatment: false,
          reading: null,
        },
      },
    };
  },
  methods: {
    cancel() {
      if (confirm("Сигурни ли сте, че искате да анулирате заявлението?")) {
        this.$apiService.put(`/examinations/cancel/${this.id}`).then(() => {
          this.$snotify.success("Заявлението е анулирано успешно");
          this.close(true);
        });
      }
    },
    print(examination) {
      this.printExamination = examination;
      this.$nextTick(() => {
        window.print();
      });
    },
    getUser(id) {
      this.user = {};
      this.$apiService.get(`/users/${id}?pacient=true`).then((response) => {
        this.user = response.data;
      });
    },
    getExamination() {
      this.status = null;
      this.$apiService.get(`/examinations/${this.id}`).then((res) => {
        this.examination = res.data;

        this.getUser(this.examination.user._id);

        this.$apiService
          .get(`/photos/examination/status/${this.id}`)
          .then((res) => {
            this.status = res.data.photos ? "finished" : "unfinished";
          });
      });
    },
    getPhotos() {
      this.photos = [];
      this.$apiService.get(`/photos/examination/${this.id}`).then((res) => {
        this.photos = res.data;

        this.photos = this.photos.sort((a, b) => {
          if (a._id === "Разчитане - преглед") {
            return 1;
          } else if (b._id === "Разчитане - преглед") {
            return -1;
          }
          return 0;
        });

        if (this.photos.length) {
          this.photosMode = true;
        }
      });
    },
    buildName() {
      const objects = {
        roentgenography: "РЕНТГЕНОГРАФИЯ НА ЗЪБ/И",
        rogr: "RO GR ЗА КАРИЕС ДИАГНОСТИКА /BITE-WING/",
        paradontalStatus: "ПАРАДОНТАЛЕН СТАТУС",
        occlusal: "ОКЛУЗАЛНИ",
        orthopantomography: "ДИГИТАЛНА ОРТОПАНТОМОГРАФИЯ",
        skull: "РЕНТГЕНОГРАФИИ НА ЧЕРЕП",
        others: "ДРУГИ ЦЕНТРАЖИ",
        svst: "КОМПЮТЪРНА ТОМОГРАФИЯ (СВСТ)",
      };
      let output = [];

      for (let o in objects) {
        if (output.length > 2) {
          break;
        }

        // when in roentgenography the condition is to have at least one checkbox selected & at least one tooth
        if (o === "roentgenography") {
          if (
            this.examination[o].teeth.length &&
            (this.examination[o].dikDigital ||
              this.examination[o].dikFilm ||
              this.examination[o].parallelDigital ||
              this.examination[o].parallelFilm)
          ) {
            output.push(output.length === 2 ? "..." : objects[o]);
          }
        } else {
          for (let x in this.examination[o]) {
            // checking if at least one prop is selected
            if (x === "teeth" && o === "svst") {
              if (
                this.examination[o][x].length &&
                this.examination[o].fourByFour
              ) {
                output.push(output.length === 2 ? "..." : objects[o]);
                break;
              }
            } else if (!(o === "svst" && x === "fourByFour")) {
              if (this.examination[o][x]) {
                output.push(output.length === 2 ? "..." : objects[o]);
                break;
              }
            }
          }
        }
      }

      return output.join(", ");
    },
    save() {
      this.examination.name = this.buildName();

      if (!this.examination.name) {
        this.$snotify.error("Не може да запазите празно направление!");
        return;
      }

      if (this.id) {
        this.$apiService
          .put(`/examinations/${this.id}`, this.examination)
          .then(() => {
            this.$snotify.success("Направлението е обновено!");
            this.close(true);
          });
      } else {
        this.$apiService.post("/examinations", this.examination).then(() => {
          if (this.currentUser.role === "dentist") {
            this.$snotify.success(
              "Направлението е изпратено успешно в 3V Рентген. Пациента може да отиде в някоя от трите ни локации"
            );
          } else {
            this.$snotify.success("Направлението е запазено!");
          }
          this.close(true);
        });
      }
    },
    editPhoto(id) {
      this.selectedPhoto = id;
      this.isPhotoUploadOpen = true;
    },
    closePhotoUpload(update) {
      this.isPhotoUploadOpen = false;
      this.selectedPhoto = null;
      if (update) {
        this.getExamination();
        this.getPhotos();
        this.$emit("refresh-examinations");
      }
    },
    close(update) {
      this.$emit("close-panel", update);
    },
    waitForResizeEnd() {
      if (window.innerWidth > 1200) {
        this.resizing = true;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (this.id) {
            this.resizing = false;
          }
        }, 500);
      }
    },
  },
  mounted() {
    if (this.id) {
      this.getExamination();
      this.getPhotos();
    } else {
      this.getUser(this.uid);
    }

    window.addEventListener("resize", this.waitForResizeEnd);
  },
  destroyed() {
    window.removeEventListener("resize", this.waitForResizeEnd);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
@import "@/scss/transitions.scss";
@import "@/scss/radio.scss";
.panel .panel__content {
  overflow: hidden;
}
.panel__content {
  width: 60vw;
  .header {
    align-items: center;
    background-color: $white--bluish;
    box-shadow: 0px 0px 20px #0000006e;
    color: $color;
    display: flex;
    height: $input-height * 2;
    justify-content: space-between;
    max-height: $input-height * 2;
    min-height: $input-height * 2;
    padding: 10px;
    position: relative;
    width: 100%;
    z-index: 1;
    .header__left {
      display: flex;
      margin-right: 20px;
      .icon {
        color: $blue;
        font-size: 2rem;
        margin-right: 10px;
      }
    }
    .buttons {
      display: flex;
      .button:not(:nth-child(1)) {
        margin-left: 10px;
      }
      .edit {
        margin: 0;
      }
    }
  }
}
.examination {
  height: 100%;
  overflow: auto;
  padding: 10px;
  .row {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
    .box:not(:first-child) {
      margin-left: 20px;
    }
  }
}
.box {
  width: 100%;
}
.box__header {
  align-items: center;
  background-color: #2a5584;
  color: #fff;
  display: flex;
  height: 42px;
  padding: 0 20px;
  h3 {
    font-size: 1.1rem;
    line-height: 1.1rem;
  }
}
.box__content {
  align-items: center;
  background-color: #f4f6fc;
  display: flex;
  padding: 10px 20px;
  height: calc(100% - 52px);
  &.box__content--start {
    align-items: flex-start;
  }
}
.checkboxes {
  display: flex;
  flex-direction: column;
  width: 100%;
  > p {
    font-weight: 700;
    white-space: nowrap;
    margin-bottom: 20px;
  }
}

.box-1 {
  .checkboxes {
    width: 20%;
    margin-right: 10px;
  }
  .teeth {
    width: 60%;
  }
}

.box-4 {
  .box__content {
    align-items: stretch;
    padding: 20px;
  }
  .checkboxes:first-child {
    width: 60%;
  }
  .checkboxes:last-child {
    align-items: flex-start;
    border-left: 1px solid rgba($color: #2a5584, $alpha: 0.5);
    justify-content: center;
    margin-left: 5%;
    padding-left: 30px;
    position: relative;
    width: 35%;

    .rotated {
      background-color: #f4f6fc;
      left: 0;
      position: absolute;
      padding: 10px 0;
      top: 50%;
      @include transform(translateX(-50%) translateY(-50%));
      p {
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.2rem;
        writing-mode: vertical-rl;
        white-space: nowrap;
        @include transform(rotate(180deg));
      }
    }
    .input-radio {
      border: 1px dashed #707070;
      border-radius: 10px;
      display: inline-flex;
      margin-top: 10px;
      padding: 10px;
    }
  }
  .halfs {
    display: flex;
    margin-top: 20px;
  }
  .half {
    display: flex;
    flex-direction: column;
    width: 50%;
    &.half--padding-right {
      padding-right: 20px;
    }
    &.half--with-border {
      border-left: 1px solid rgba($color: #2a5584, $alpha: 0.5);
      padding-left: 20px;
    }
  }
}

.big-checkboxes-row {
  display: flex;
  margin-left: 5px;
  .big-checkbox:not(:first-child) {
    margin-left: 10px;
  }
}
.big-checkboxes-three {
  border-collapse: collapse;
  td {
    padding: 8px;
  }
  td:not(:first-child) {
    border-left: 1px solid #416184;
  }
}
.big-checkboxes-quadrant {
  border-collapse: collapse;
  td {
    padding: 8px;
  }

  tr:first-child {
    td:first-child {
      border-bottom: 1px solid #416184;
      border-right: 1px solid #416184;
    }
  }
  tr:last-child {
    td:last-child {
      border-top: 1px solid #416184;
      border-left: 1px solid #416184;
    }
  }
}

.print__header {
  display: none;
}

@media screen and (max-width: $xl) {
  .panel__content {
    .header {
      height: $xl-input-height * 2;
      max-height: $xl-input-height * 2;
      min-height: $xl-input-height * 2;
    }
  }
}

@media screen and (max-width: $l) {
  .panel__content {
    width: 70vw;
  }
  .box-4 {
    .checkboxes:first-child {
      width: 60%;
    }
    .checkboxes:last-child {
      margin-left: 3%;
      padding-left: 25px;
      width: 37%;
    }
  }
}

@media screen and (max-width: $m) {
  .panel__content {
    .box__header {
      h3 {
        font-size: 1rem;
      }
    }
  }
}
@media screen and (max-width: $sm) {
  .examination {
    height: 100%;
    overflow: auto;
    padding: 10px;
    .row {
      flex-direction: column;
      .box:not(:first-child) {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }

  .box__content {
    flex-flow: row wrap;
  }

  .checkboxes {
    > p {
      margin: 20px 0;
    }
  }

  .box-1 {
    .checkboxes {
      margin-right: 0;
      padding-right: 10px;
      width: calc(50% - 5px);
    }
    .teeth {
      width: 100%;
    }
  }

  .box-4 {
    .checkboxes:first-child {
      width: 100%;
    }
    .checkboxes:last-child {
      border-left: 0;
      border-top: 1px solid rgba(42, 85, 132, 0.5);
      margin-left: 0;
      margin-top: 20px;
      padding-left: 0;
      padding-top: 20px;
      width: 100%;

      .rotated {
        left: 50%;
        top: 0;
        p {
          transform: initial;
          writing-mode: initial;
        }
      }
    }
    .halfs {
      flex-direction: column;
    }
    .half {
      width: 100%;
      &.half--with-border {
        border-left: 0;
        border-top: 1px solid rgba(42, 85, 132, 0.5);
        padding-left: 0;
        padding-top: 20px;
        margin-top: 20px;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .panel__content {
    width: calc(100vw - 50px);
  }
}
@media screen and (max-width: $xs) {
  .box-1 {
    .checkboxes {
      width: 100%;
    }
  }
}

@media print {
  .no-print {
    display: none !important;
  }
  * {
    page-break-before: avoid;
    page-break-after: avoid;
    page-break-inside: avoid;
  }
  .panel__content {
    width: 100%;
    .header {
      display: none;
    }
    .box__header {
      h3 {
        font-size: 11px;
      }
    }
  }
  .print__header {
    display: block;
    padding: 10px;
    header {
      display: flex;
      .logo {
        align-items: center;
        background-color: #2a5584;
        display: flex;
        justify-content: center;
        padding: 10px;
        width: 60%;
        img {
          height: 80px;
          &:last-child {
            margin-left: 25px;
            height: 40px;
          }
        }
      }
      .title {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;
        h1 {
          font-size: 20px;
          line-height: 20px;
          text-align: right;
        }
      }
    }
    .placeholder {
      font-size: 11px;
      white-space: nowrap;
      p {
        border-bottom: 1px solid #000;
        padding: 5px 10px;
        font-weight: 700;
        min-height: 21px;
        text-align: center;
      }
      span {
        display: block;
        text-align: center;
        margin-top: 2px;
      }
    }
    .row {
      align-items: flex-end;
      display: flex;
      .placeholder {
        margin-top: 5px;
        width: 100%;
      }
    }
  }

  .examination .row .box:not(:first-child) {
    margin-left: 10px;
  }

  .checkboxes {
    > p {
      margin-bottom: 5px;
      font-size: 10px;
    }
  }

  .panel__content {
    .box__header {
      height: 34px;
      h3 {
        font-size: 10px;
      }
    }
    .box__content {
      height: calc(100% - 34px);
      padding: 5px 20px;
    }
  }

  .big-checkboxes-row .big-checkbox:not(:first-child) {
    margin-left: 4px;
  }

  .big-checkboxes-three td,
  .big-checkboxes-quadrant td {
    padding: 4px;
  }

  .box-1 {
    .checkboxes {
      width: 25%;
      margin-right: 10px;
    }
    .teeth {
      width: 50%;
    }
  }

  .box-4 {
    .half.half--padding-right {
      padding-right: 8px;
    }
    .half.half--with-border {
      padding-left: 8px;
    }
    .checkboxes:first-child {
      width: 60%;
    }
    .checkboxes:last-child {
      margin-left: 3%;
      padding-left: 20px;
      width: 37%;
      .rotated p {
        font-size: 12px;
      }
      .input-radio {
        // flex-direction: column;
        label {
          // margin-bottom: 5px;
        }
      }
    }
  }
}
</style>