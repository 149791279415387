<template>
  <div
    class="big-checkbox"
    :class="{ 'big-checkbox--active': value, 'big-checkbox--disabled': disabled  }"
    @click="clicked()"
  >
    <span class="icon" v-if="icon">{{ icon }}</span>
    <span v-else>{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: ['value', 'label', 'disabled', 'icon'],
  methods: {
    clicked() {
      if (!this.disabled) {
        this.$emit('input', !this.value);
        this.$emit(!this.value ? 'activated' : 'deactivated');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.big-checkbox {
  align-items: center;
  background-color: #cfcfcf;
  border-radius: 4px;
  display: flex;
  height: 45px;
  justify-content: center;
  width: 45px;
  @include transition(all $transition $cubic);
  @include noselect();
  &:not(.big-checkbox--disabled) {
    cursor: pointer;
  }
  span {
    color: #416184;
    font-size: 36px;
    @include transition(all $transition $cubic);
  }
  &.big-checkbox--active {
    background-color: $blue;
    span {
      color: #fff;
    }
  }
  &:not(.big-checkbox--disabled):not(.big-checkbox--active):hover {
    background-color: #4da1ff80;
    span {
      color: #fff;
    }
  }
}

@media screen and (max-width: $xl) {
  .big-checkbox {
    height: 32px;
    width: 32px;
    span {
      font-size: 24px;
    }
  }
}
@media print {
  .big-checkbox {
    height: 22px;
    width: 22px;
    span {
      font-size: 14px;
    }
  }
}
</style>