<template>
  <div class="photos">
    <transition name="fade" mode="out-in">
      <div key="photos-wrapper" v-if="photos.length && ready && !hide">
        <div class="photos-group" v-for="(pg, i) of photos" :key="`pg-${i}`">
          <header>
            {{ pg._id }}
            <template v-if="pg._id !== 'Разчитане - преглед'">
              - {{ pg.photos.length }} снимк{{
                pg.photos.length === 1 ? "а" : "и"
              }}
            </template>
          </header>
          <div
            class="photo"
            v-for="(p, j) of pg.photos"
            :key="p._id"
            @click="
              isDoc(p.photo)
                ? openFile(`${uploads}/photos/${p.examination}/${p.photo}`)
                : canPreview(p.photo)
                ? ((selectedPhoto = `${p.examination}/${p.photo}`),
                  (selectedPhotoCategory = p.category))
                : null
            "
          >
            <Cornerstone
              :path="`${p.examination}/${p.photo}`"
              v-if="canPreview(p.photo)"
            />
            <div class="doc-preview" v-else-if="isDoc(p.photo)">
              <img src="@/assets/img/doc.svg" alt="Без preview" />
            </div>
            <div class="no-preview" v-else>
              <img src="@/assets/img/no-image.svg" alt="Без preview" />
            </div>
            <footer>
              <p v-if="p.category === 'Разчитане - преглед'">Разчитане</p>
              <p v-else>
                Снимка {{ j + 1 }}
                <template v-if="p.teeth.length"
                  >- {{ p.teeth.length > 1 ? "зъби" : "зъб" }}
                  {{ p.teeth.join(", ") }}</template
                >
              </p>
              <p>
                <router-link
                  class="download"
                  :to="`/to-jpg/${p.examination}/${p.photo}`"
                  target="_blank"
                  @click.stop.native
                  v-if="p.photo.match(/\.(dcm|DCM|dic|DIC|dicom|DICOM)$/)"
                >
                  <span class="icon">f</span>
                  JPEG
                </router-link>
                <a
                  class="download"
                  :href="`${uploads}/photos/${p.examination}/${p.photo}`"
                  target="_blank"
                  @click.stop
                >
                  <span class="icon">f</span>
                  <template
                    v-if="p.photo.match(/\.(dcm|DCM|dic|DIC|dicom|DICOM)$/)"
                    >DICOM</template
                  >
                  <template v-else-if="p.photo.match(/\.(jpg|JPG|jpeg|JPEG)$/)"
                    >JPEG</template
                  >
                  <template v-else-if="p.photo.match(/\.(bmp|BMP)$/)"
                    >BMP</template
                  >
                  <template v-else-if="p.photo.match(/\.(ilumact|ILUMACT)$/)"
                    >ILUMACT</template
                  >
                  <template v-else-if="p.photo.match(/\.(zip|ZIP)$/)"
                    >ZIP</template
                  >
                  <template v-else-if="p.photo.match(/\.(doc|DOC|docx|DOCX)$/)"
                    >WORD</template
                  >
                </a>
                <span
                  class="icon edit"
                  @click.stop="editImage(p._id)"
                  v-if="
                    (currentUser.role === 'admin' ||
                      currentUser.role === 'employee') &&
                    p.category !== 'Разчитане - преглед'
                  "
                  >J</span
                >
                <span
                  class="icon"
                  @click.stop="deleteImage(p._id)"
                  v-if="
                    currentUser.role === 'admin' ||
                    currentUser.role === 'employee'
                  "
                  >e</span
                >
              </p>
            </footer>
          </div>
        </div>
      </div>
      <p key="loading" v-else-if="photos.length">Зареждане</p>
      <p key="no-photos" v-else>Няма добавени снимки</p>
    </transition>
    <transition name="fade">
      <PhotoModal
        v-if="selectedPhoto"
        :path="selectedPhoto"
        :category="selectedPhotoCategory"
        @close="(selectedPhoto = null), (selectedPhotoCategory = null)"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Cornerstone from "@/components/Cornerstone.vue";
import PhotoModal from "@/components/PhotoModal.vue";
export default {
  props: ["photos", "hide"],
  components: {
    Cornerstone,
    PhotoModal,
  },
  computed: {
    ...mapGetters(["currentUser"]),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
  },
  data() {
    return {
      ready: false,
      selectedPhoto: null,
      selectedPhotoCategory: "",
    };
  },
  methods: {
    openFile(url) {
      window.open(url)
    },
    canPreview(i) {
      return i.toLowerCase().match(/\.(dcm|dic|dicom|jpg|jpeg|bmp)$/);
    },
    isDoc(i) {
      return i.toLowerCase().match(/\.(doc|docx)$/);
    },
    editImage(id) {
      this.$emit("edit-photo", id);
    },
    deleteImage(id) {
      if (confirm("Потвърдете изтриването")) {
        this.$apiService.delete(`/photos/${id}`).then(() => {
          this.$emit("update-photos");
        });
      }
    },
  },
  mounted() {
    setTimeout(() => (this.ready = true), 500);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
@import "@/scss/transitions.scss";
.photos {
  overflow: auto;
  height: 100%;
  padding: 10px;

  .photos-group {
    display: flex;
    flex-flow: row wrap;
    > header {
      background-color: #d5e1f2;
      border: 1px solid #c8c8c8;
      color: rgba($color: #414141, $alpha: 0.8);
      font-size: 1.1rem;
      font-weight: 700;
      margin-bottom: 10px;
      padding: 10px;
      width: 100%;
    }
    .photo {
      background-color: #f2f2f2;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      padding: 5px;
      width: calc(50% - 5px);
      @include hover();
      .cornerstone-canvas {
        border-radius: 4px;
        overflow: hidden;
        width: 100%;
      }
      .no-preview {
        display: flex;
        justify-content: center;
        padding: 10px;
        height: 100%;
      }
      .doc-preview {
        display: flex;
        justify-content: center;
        padding: 10px;
        height: 100%;
        img {
          height: 150px;
        }
      }
      img {
        width: 40%;
      }
      &:nth-child(odd) {
        margin-left: 10px;
      }
      footer {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.5px;
        margin-top: 7.5px;
        > p {
          align-items: center;
          display: flex;
        }
        .icon {
          font-size: 1.4rem;
          @include transition(all $transition $cubic);
          &:hover {
            color: #810900;
          }
          &.edit {
            margin-right: 5px;
            &:hover {
              color: #0077c7;
            }
          }
        }
        .download {
          align-items: center;
          background-color: #fff;
          border: 1px solid #b5b5b5;
          border-radius: 2px;
          color: #414141;
          display: inline-flex;
          font-size: 0.8rem;
          height: 24px;
          justify-content: space-between;
          line-height: 0.8rem;
          margin: -2px 8px -2px 0;
          padding: 0 5px;
          width: 70px;
          @include transition(all $transition $cubic);
          .icon {
            color: inherit;
            font-size: 1rem;
            line-height: 1rem;
          }
          &:hover {
            background-color: rgba($color: #000, $alpha: 0.05);
          }
        }
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .photos {
    .photos-group {
      .photo {
        width: 100%;
        &:nth-child(odd) {
          margin: 0 0 10px;
        }
      }
    }
  }
}

@media print {
  .photos {
    display: none;
  }
}
</style>