<template>
  <div class="checkbox-container" :class="{ 'checkbox-container--disabled': disabled }">
    <label class="checkbox-wrapper">
      <input
        type="checkbox"
        :disabled="disabled"
        :checked="value"
        @change="$emit('input', $event.target.checked), $emit($event.target.checked ? 'activated' : 'deactivated')"
      />
      <span class="checkmark"></span>
      <p>{{ label }}</p>
    </label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['value', 'label', 'disabled']
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.checkbox-container {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}
.checkbox-wrapper {
  align-items: center;
  // color: #fff;
  cursor: pointer;
  display: inline-flex;
  height: 27px;
  margin: 10px 0;
  padding-left: 37px;
  position: relative;
  @include noselect();

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  p {
    margin: 0;

    a {
      @include hover();
    }
  }
}

/* Create a custom checkbox */
.checkmark {
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #1d1d1d;
  height: 27px;
  left: 0;
  position: absolute;
  top: 0;
  @include transition(background-color $transition $cubic);
  width: 27px;
}

.checkbox-wrapper:hover input:not(:disabled):not(:checked) ~ .checkmark {
  background-color: $blue--hover;
  border: 1px solid #1d1d1d;
}

.checkbox-wrapper input:checked ~ .checkmark {
  background-color: $blue;
  border: 1px solid $blue;
}

.checkmark:after {
  content: '';
  display: none;
  position: absolute;
}

.checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-wrapper .checkmark:after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  height: 12px;
  left: 10px;
  top: 5px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 7px;
}

.checkbox-container--disabled {
  .checkbox-wrapper {
    cursor: default;
    input {
      cursor: default;
    }
  }
}

@media screen and (max-width: $xl) {
  .checkbox-wrapper {
    height: 24px;
    margin: 7px 0;
    padding-left: 34px;
  }
  .checkmark {
    height: 24px;
    width: 24px;
  }

  .checkbox-wrapper .checkmark:after {
    height: 10px;
    left: 8px;
    top: 5px;
    width: 6px;
  }
}

@media print {
  .checkbox-wrapper {
    height: 18px;
    margin: 4px 0;
    padding-left: 26px;
    p {
      font-size: 10px;
      line-height: 10px;
    }
  }
  .checkmark {
    height: 18px;
    width: 18px;
  }

  .checkbox-wrapper .checkmark:after {
    height: 10px;
    left: 5px;
    top: 1px;
    width: 6px;
  }
}
</style>