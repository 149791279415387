<template>
  <div class="print-card">
    <div class="img">
      <img src="../../assets/img/print/kHdr.svg" alt="Header" />
    </div>
    <main>
      <p>
        <span>Пациент:</span>
        <span>
          <strong>{{ u.name }}</strong>
        </span>
      </p>
      <!-- <p>
        <span>ЕНГ/Рождена дата:</span>
        <span>
          <strong>{{ u.egn }}</strong>
        </span>
      </p> -->
      <p>
        <span>Потребителско име:</span>
        <span>
          <strong>{{ u.username }}</strong>
        </span>
      </p>
      <p>
        <span>Парола:</span>
        <span>
          <strong>{{ u.password }}</strong>
        </span>
      </p>
    </main>
    <div class="img">
      <img src="../../assets/img/print/kFtr.svg" alt="Footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['u']
};
</script>

<style lang="scss" scoped>
.print-card {
  display: none;
}

@media print {
  .print-card {
    border: 1px solid #2a5584;
    display: flex;
    flex-direction: column;
    height: 9.5cm;
    margin: 0.5cm auto;
    width: 9.5cm;
    .img {
      display: flex;
      img {
        height: auto;
        width: 100%;
      }
    }
    main {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 30px;
      p {
        align-items: center;
        border-bottom: 2px dashed rgba($color: #2a5584, $alpha: 0.5);
        color: #223432;
        display: flex;
        justify-content: space-between;
        height: 25%;
        span:first-child {
          font-size: 14px;
        }
        strong {
          font-size: 15px;
        }
      }
    }
  }
}
</style>