var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"photos"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.photos.length && _vm.ready && !_vm.hide)?_c('div',{key:"photos-wrapper"},_vm._l((_vm.photos),function(pg,i){return _c('div',{key:`pg-${i}`,staticClass:"photos-group"},[_c('header',[_vm._v(" "+_vm._s(pg._id)+" "),(pg._id !== 'Разчитане - преглед')?[_vm._v(" - "+_vm._s(pg.photos.length)+" снимк"+_vm._s(pg.photos.length === 1 ? "а" : "и")+" ")]:_vm._e()],2),_vm._l((pg.photos),function(p,j){return _c('div',{key:p._id,staticClass:"photo",on:{"click":function($event){_vm.isDoc(p.photo)
              ? _vm.openFile(`${_vm.uploads}/photos/${p.examination}/${p.photo}`)
              : _vm.canPreview(p.photo)
              ? ((_vm.selectedPhoto = `${p.examination}/${p.photo}`),
                (_vm.selectedPhotoCategory = p.category))
              : null}}},[(_vm.canPreview(p.photo))?_c('Cornerstone',{attrs:{"path":`${p.examination}/${p.photo}`}}):(_vm.isDoc(p.photo))?_c('div',{staticClass:"doc-preview"},[_c('img',{attrs:{"src":require("@/assets/img/doc.svg"),"alt":"Без preview"}})]):_c('div',{staticClass:"no-preview"},[_c('img',{attrs:{"src":require("@/assets/img/no-image.svg"),"alt":"Без preview"}})]),_c('footer',[(p.category === 'Разчитане - преглед')?_c('p',[_vm._v("Разчитане")]):_c('p',[_vm._v(" Снимка "+_vm._s(j + 1)+" "),(p.teeth.length)?[_vm._v("- "+_vm._s(p.teeth.length > 1 ? "зъби" : "зъб")+" "+_vm._s(p.teeth.join(", ")))]:_vm._e()],2),_c('p',[(p.photo.match(/\.(dcm|DCM|dic|DIC|dicom|DICOM)$/))?_c('router-link',{staticClass:"download",attrs:{"to":`/to-jpg/${p.examination}/${p.photo}`,"target":"_blank"},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"icon"},[_vm._v("f")]),_vm._v(" JPEG ")]):_vm._e(),_c('a',{staticClass:"download",attrs:{"href":`${_vm.uploads}/photos/${p.examination}/${p.photo}`,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"icon"},[_vm._v("f")]),(p.photo.match(/\.(dcm|DCM|dic|DIC|dicom|DICOM)$/))?[_vm._v("DICOM")]:(p.photo.match(/\.(jpg|JPG|jpeg|JPEG)$/))?[_vm._v("JPEG")]:(p.photo.match(/\.(bmp|BMP)$/))?[_vm._v("BMP")]:(p.photo.match(/\.(ilumact|ILUMACT)$/))?[_vm._v("ILUMACT")]:(p.photo.match(/\.(zip|ZIP)$/))?[_vm._v("ZIP")]:(p.photo.match(/\.(doc|DOC|docx|DOCX)$/))?[_vm._v("WORD")]:_vm._e()],2),(
                  (_vm.currentUser.role === 'admin' ||
                    _vm.currentUser.role === 'employee') &&
                  p.category !== 'Разчитане - преглед'
                )?_c('span',{staticClass:"icon edit",on:{"click":function($event){$event.stopPropagation();return _vm.editImage(p._id)}}},[_vm._v("J")]):_vm._e(),(
                  _vm.currentUser.role === 'admin' ||
                  _vm.currentUser.role === 'employee'
                )?_c('span',{staticClass:"icon",on:{"click":function($event){$event.stopPropagation();return _vm.deleteImage(p._id)}}},[_vm._v("e")]):_vm._e()],1)])],1)})],2)}),0):(_vm.photos.length)?_c('p',{key:"loading"},[_vm._v("Зареждане")]):_c('p',{key:"no-photos"},[_vm._v("Няма добавени снимки")])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.selectedPhoto)?_c('PhotoModal',{attrs:{"path":_vm.selectedPhoto,"category":_vm.selectedPhotoCategory},on:{"close":function($event){(_vm.selectedPhoto = null), (_vm.selectedPhotoCategory = null)}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }